import merge from 'lodash/merge';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import { useChartColors } from '../../utils/core';

// ----------------------------------------------------------------------

export default function useChart(options) {
  const theme = useTheme();

  const LABEL_VALUE = {
    offsetY: 8,
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: theme.typography.h3.lineHeight
  };

  const allColors = useChartColors();
  const baseOptions = {
    // Colors
    colors: allColors,

    // Chart
    chart: {
      toolbar: { show: true },
      zoom: { enabled: false },
      // animations: { enabled: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily
    },

    // States
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.04
        }
      },
      active: {
        filter: {
          type: 'darken',
          value: 0.88
        }
      }
    },

    // Fill
    fill: {
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100]
      }
    },

    // Datalabels
    dataLabels: {
      enabled: ['donut', 'pie'].includes(options.chart.type)
    },

    // Stroke
    stroke: {
      show: true,
      width: ['donut', 'pie'].includes(options.chart.type) ? 1 : 3,
      curve: 'straight',
      lineCap: 'round'
      // colors: ['transparent']
    },

    // Grid
    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider
    },

    // Xaxis
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false }
    },

    // Markers
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper
    },

    // Title
    title: {
      align: 'center',
      style: {
        fontSize: '16px',
        color: theme.palette.text.primary
      }
    },

    // SubTitle
    subtitle: {
      text: undefined,
      align: 'center',
      style: {
        fontSize: '12px',
        color: theme.palette.text.disabled
        //    color: '#9699a2'
      }
    },

    // Tooltip
    tooltip: {
      x: {
        show: true
      }
    },

    // Legend
    legend: {
      show: true,
      fontSize: String(12),
      position: 'bottom',
      horizontalAlign: 'center',
      markers: {
        radius: 12
      },
      fontWeight: 500,
      itemMargin: { horizontal: 12 },
      labels: {
        colors: theme.palette.text.primary
      }
    },

    // plotOptions
    plotOptions: {
      // Bar
      bar: {
        // columnWidth: '16%',
        endingShape: 'rounded',
        borderRadiusApplication: 'end',
        horizontal: false,
        borderRadius: 4,
        dataLabels: {
          // position: 'top',
          // maxItems: 100,
          // hideOverflowingLabels: true,
          // orientation: 'horizontal',
          total: {
            enabled: false,
            formatter: undefined
            // offsetX: 0,
            // offsetY: 0
            // style: {
            //   color: '#373d3f',
            //   fontSize: '12px',
            //   fontFamily: undefined,
            //   fontWeight: 600
            // }
          }
        }
      },
      // Pie + Donut
      pie: {
        customScale: 0.9,
        donut: {
          labels: {
            // show: true,
            value: LABEL_VALUE
          }
        }
      },
      // Radialbar
      radialBar: {
        track: {
          strokeWidth: '100%',
          background: alpha(theme.palette.grey[500], 0.16)
        },
        dataLabels: {
          value: LABEL_VALUE
        }
      },
      // Radar
      radar: {
        size: 150,
        polygons: {
          fill: { colors: ['transparent'] },
          strokeColors: theme.palette.divider,
          connectorColors: theme.palette.divider
        }
      },
      // polarArea
      polarArea: {
        rings: {
          strokeColor: theme.palette.divider
        },
        spokes: {
          connectorColors: theme.palette.divider
        }
      }
    },

    // Responsive
    responsive: [
      {
        // sm
        breakpoint: theme.breakpoints.values.sm,
        options: {
          plotOptions: { bar: { columnWidth: '40%' } }
        }
      },
      {
        // md
        breakpoint: theme.breakpoints.values.md,
        options: {
          plotOptions: { bar: { columnWidth: '32%' } }
        }
      }
    ]
  };

  return merge(baseOptions, options);
}

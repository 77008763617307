import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  feedBack: null,
  selectedInvoiceType: null,
  defaultEntity: null,
  calculated: null,
  parameters: null,
  progress: { value: 0, message: null },
  dialogOpen: false,
  request: null
};

const slice = createSlice({
  name: 'invoiceRequest',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedInvoiceType(state, action) {
      state.selectedInvoiceType = action.payload;
    },

    setDefaultEntity(state, action) {
      state.defaultEntity = action.payload;
    },

    setCalculated(state, action) {
      state.calculated = action.payload;
    },

    setDialogOpen(state, action) {
      state.dialogOpen = action.payload;
    },

    setProgress(state, action) {
      state.progress = { ...state.progress, ...action.payload };
    },

    setInvoiceParameters(state, action) {
      state.parameters = { ...state.parameters, ...action.payload };
    },

    setRequestFeedBack(state, action) {
      state.feedBack = action.payload;
    },

    buildRequest(state, action) {
      if (action.payload === null) {
        state.calculated = null;
      }
      state.request = { ...state.request, ...action.payload };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setSelectedInvoiceType,
  buildRequest,
  setDefaultEntity,
  setProgress,
  setRequestFeedBack,
  setCalculated,
  setDialogOpen
} = slice.actions;

// ----------------------------------------------------------------------

export function setDefaultParams(params, invoiceTypes) {
  return async (dispatch) => {
    if (invoiceTypes !== null) {
      const invoiceType = invoiceTypes.find((iType) => iType.id === parseInt(params.invoiceId, 10));
      const defaults = {
        skip_elements: invoiceType.skip_elements,
        invoice_type_id: parseInt(params.invoiceId, 10),
        month: invoiceType.frequency.months.length === 12 ? parseInt(params.period, 10) : undefined,
        quarter: invoiceType.frequency.months.length === 4 ? parseInt(params.period, 10) : undefined,
        year: parseInt(params.year, 10),
        entity_id: parseInt(params.entityId, 10),
        isSubmitting: false
      };
      dispatch(slice.actions.setSelectedInvoiceType(invoiceType));
      dispatch(getInvoiceParameters(invoiceType));
      dispatch(slice.actions.buildRequest(defaults));
    }
  };
}

export function calculate(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setCalculated(null));
    dispatch(slice.actions.buildRequest({ ...params, isSubmitting: true }));
    try {
      const response = await axios.post('/api/invoices/calculate/', params);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function getInvoiceLeaves(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setCalculated(null));
    dispatch(slice.actions.buildRequest({ ...params, isSubmitting: true }));
    try {
      const response = await axios.post('/api/data/leaves/', params);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.buildRequest({ ...params, isSubmitting: false }));
      dispatch(slice.actions.setRequestFeedBack({ type: 'error', message: "Couldn't load leaves" }));
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

// Get entity for invoice
export function getEntity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/entities/edit/${id}`);
      dispatch(slice.actions.setDefaultEntity(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoiceParameters(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setInvoiceParameters({ isLoading: true }));
    try {
      const response = await axios.post('/api/invoices/parameters', params);
      dispatch(slice.actions.setInvoiceParameters({ ...response.data, isLoading: false }));
      dispatch(slice.actions.buildRequest({ kind: response.data.period_picker.kind }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import { flatMapDeep, filter } from 'lodash';

/* eslint-disable */
export const filterTree = (tree, target) => {
  if (tree.value === target) {
    return tree;
  }
  for (const child of tree.children) {
    const res = filterTree(child, target);
    if (res) {
      return res;
    }
  }
};

const fetchLeaves = (node, leaves) => {
  leaves = leaves || [];
  if (node.leaf) {
    leaves.push(node);
  } else {
    for (const child of node.children) {
      fetchLeaves(child, leaves);
    }
  }
  return leaves;
};

/* eslint-enable */

export const updateTree = (tree, updates, mode) => {
  tree.forEach((node) => {
    if (node.value === updates.value) {
      node.checked = updates.checked;
      if (node.children.length > 0 && ['selectAll', 'leavesOnly'].includes(mode)) {
        commitChildren(node, updates.checked);
      }
    } else if (node && node.children && node.children.length > 0) {
      updateTree(node.children, updates, mode);
    }
  });
  return tree;
};

const commitChildren = (parent, check) => {
  if (parent.children.length > 0) {
    parent.children.forEach((child) => {
      child.checked = check;
      commitChildren(child, check);
    });
  }
};

const getNodes = (node) => {
  const cleanNode = { value: node.value, label: node.label, checked: node.checked, leaf: node.leaf };

  if (!node.children || !node.children.length) {
    return cleanNode;
  }
  return [cleanNode, flatMapDeep(node.children, getNodes)];
};

export const filterCheckedLeaves = (tree) => filter(flatMapDeep(tree, getNodes), { checked: true, leaf: true });
export const filterCheckedAllNodes = (tree) => filter(flatMapDeep(tree, getNodes), { checked: true });
